<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">
		<div class="pa-5 pa-sm-10 fill-height" v-if="AppStore.started_app" id="questions-page">
			<v-container class="fill-height d-flex align-center flex-row" fluid>
				<v-row align="center" justify="center">
					<v-card elevation="0" class="mx-auto px-5 py-sm-5 px-2 px-sm-10 ma-5" rounded="xl">
						<stepper :step="1" :steps="3"
						         v-if="$vuetify.breakpoint.smAndUp && AppStore.data.configuration.display_stepper"></stepper>

						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center my-auto" id="question-title"
						       v-if="AppStore.data.configuration.display_question_title">
							<span v-html="AppStore.data.configuration[AppStore.locale].question_title"></span>
						</v-col>

						<v-col cols="12 grow" class="pb-0" v-if="AppStore.context_questions.length"
						       id="context-questions">
							<div v-for="(question, questionIndex2) in AppStore.context_questions"
							     :key="'100'+questionIndex2">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
								             v-if="question.format === 'slider'"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></slider-view>
								<text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								           v-if="question.format === 'text'"
								           :rules="question.is_required ? 'required' : ''"
								           v-model="AppStore.context_questions[questionIndex2]"
								           :questionIndex="questionIndex2" :lang="AppStore.locale"></text-view>
								<textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'textarea'"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="AppStore.context_questions[questionIndex2]"
								               :questionIndex="questionIndex2"
								               :lang="AppStore.locale"></textarea-view>
								<select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'select'"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></select-view>
								<checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'checkbox'"
								               :rules="question.is_required ? 'required' : ''"
								               :color="AppStore.data.general.primary_color"
								               v-model="AppStore.context_questions[questionIndex2]"
								               :questionIndex="questionIndex2"
								               :lang="AppStore.locale"></checkbox-view>
								<radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								            v-if="question.format === 'radio'"
								            :rules="question.is_required ? 'required' : ''"
								            :color="AppStore.data.general.primary_color"
								            v-model="AppStore.context_questions[questionIndex2]"
								            :questionIndex="questionIndex2" :lang="AppStore.locale"></radio-view>
								<switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'switch'"
								             :rules="question.is_required ? 'required' : ''"
								             :color="AppStore.data.general.primary_color"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" class="pt-0" v-if="AppStore.profil_questions.length" id="profil-questions">
							<div v-for="(question, questionIndex) in AppStore.profil_questions" :key="questionIndex">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'slider'"
								             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								           v-if="question.format === 'text'"
								           :rules="question.is_required ? 'required' : ''"
								           v-model="AppStore.profil_questions[questionIndex]"
								           :questionIndex="questionIndex"
								           :lang="AppStore.locale"></text-view>
								<textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'textarea'"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="AppStore.profil_questions[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'select'"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'checkbox'"
								               :rules="question.is_required ? 'required' : ''"
								               :color="AppStore.data.general.primary_color"
								               v-model="AppStore.profil_questions[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								            v-if="question.format === 'radio'"
								            :color="AppStore.data.general.primary_color"
								            :rules="question.is_required ? 'required' : ''"
								            v-model="AppStore.profil_questions[questionIndex]"
								            :questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'switch'"
								             :rules="question.is_required ? 'required' : ''"
								             :color="AppStore.data.general.primary_color"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" class="text-center">
							<div v-if="!AppStore.toggleCGU">
								<v-btn
									@click="passes(submit)"
									:large="AppStore.data.general.button_size === 'large'"
									:x-large="AppStore.data.general.button_size === 'x-large'"
									:x-small="AppStore.data.general.button_size === 'x-small'"
									:small="AppStore.data.general.button_size === 'small'"
									v-if="answersLength === answersLengthes"
									@keypress.enter="navigate" role="link" rounded
									outlined elevation="0"
									:style="Helpers.getButtonSize(AppStore.data.general.button_size)"
									:color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"
									v-html="AppStore.data.configuration[AppStore.locale].skip_btn">
								</v-btn>
								<v-btn @click="passes(submit)" role="link" rounded v-if="answersLength < answersLengthes"
								       elevation="0" dark
								       :large="AppStore.data.general.button_size === 'large'"
								       :x-large="AppStore.data.general.button_size === 'x-large'"
								       :x-small="AppStore.data.general.button_size === 'x-small'"
								       :small="AppStore.data.general.button_size === 'small'"
								       @keypress.enter="navigate"
								       class="text-button"
								       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								       :color="AppStore.data.general.primary_color.hexa"
								       v-html="AppStore.data.configuration[AppStore.locale].send_btn">
								</v-btn>
							</div>
						</v-col>
					</v-card>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>

</template>

<script>
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import Helpers from "../../../services/Helpers";
import SliderView from '../../../components/communs/inputViewer/SliderView.vue'
import TextView from '../../../components/communs/inputViewer/TextView.vue'
import TextareaView from '../../../components/communs/inputViewer/TextareaView.vue'
import SelectView from '../../../components/communs/inputViewer/SelectView.vue'
import CheckboxView from '../../../components/communs/inputViewer/CheckboxView.vue'
import RadioView from '../../../components/communs/inputViewer/RadioView.vue'
import SwitchView from '../../../components/communs/inputViewer/SwitchView.vue'
import {EventBus} from "../../../services/Request";
import App from "../../../App.vue";
import DividerView from "../../../components/communs/inputViewer/DividerView.vue";
import Stepper from "../../../components/communs/Stepper.vue";
import {ValidationObserver} from 'vee-validate';

export default {
	name: 'questions-view',

	components: {
		Stepper,
		ValidationObserver,
		DividerView,
		SliderView,
		TextView,
		TextareaView,
		SelectView,
		CheckboxView,
		RadioView,
		SwitchView,
	},

	props: {},

	data: () => ({
		AppStore,
		Helpers,
		EventBus,
		answersLength: Object.keys(AppStore.rating.questions_answers).length,
	}),

	created() {

	},

	mounted() {
		EventBus.$on('update-question', (data) => {
			if (data.show) {
				// console.log(data.id)
				// console.log(this.$refs['qi' + data.id])
				this.$refs['qi' + data.id][0].$el.classList.remove('d-none')
			} else {
				// console.log(data.id)
				// console.log(this.$refs['qi' + data.id])
				this.$refs['qi' + data.id][0].$el.classList.add('d-none')
				delete AppStore.rating.questions_answers[data.id]
				this.$refs['qi' + data.id][0].sliderValue = 0
			}
		});
	},

	computed: {
		App() {
			return App
		},
		answersLengthes() {
			return Object.keys(AppStore.rating.questions_answers).length
		}
	},

	watch: {
		_() {
			return _;
		},
	},

	methods: {
		submit() {
			this.$router.push({name: 'tb-2-comment'})
		},
	}
};
</script>
